.footer {
  background-color: var(--cream-color);
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .wrapper {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5rem 0 2rem 0;
    padding: 10px;
    border-top: 1px solid rgb(199, 202, 199);

    @media (max-width: 1000px) {
      margin: 3rem 0 2rem 0;
    }

    .top {
      display: flex;
      justify-content: space-between;
      gap: 25px;
      width: 100%;

      @media (max-width: 1000px) {
        flex-direction: column;
      }

      .left {
        width: 34%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @media (max-width: 1000px) {
          width: 100%;
        }

        img {
          width: 200px;
        }

        .desc {
          width: 200px;
          font-size: 14px;
          font-weight: 900;
          text-align: center;
          margin-bottom: 20px;
        }

        .list {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;

          .listItem {
            background-color: var(--primary-color);
            color: var(--cream-color);
            padding: 8px;
            border-radius: 50%;
          }
        }
      }

      .center {
        display: flex;
        flex-direction: column;
        gap: 25px;
        width: 33%;

        @media (max-width: 1000px) {
          width: 100%;
          align-items: center;
        }

        .title {
          font-size: 16px;
          font-weight: 900;
          text-transform: uppercase;
        }

        .info {
          display: flex;
          align-items: center;
          gap: 10px;

          @media (max-width: 1000px) {
            width: 80%;
            justify-content: flex-start;
          }

          .iconContainer {
            font-size: 2rem;
            color: var(--primary-color);
          }

          .detail {
            span {
              font-size: 13px;
              font-weight: 900;
              text-transform: uppercase;
            }

            p {
              font-size: 12px;
              font-weight: 900;
              color: rgb(61, 61, 61);
            }
          }
        }
      }

      .right {
        flex: 2;
        display: flex;
        flex-direction: column;
        gap: 25px;

        @media (max-width: 1000px) {
          width: 100%;
        }

        .title {
          font-size: 16px;
          font-weight: 900;
          text-transform: uppercase;
          text-align: center;
        }

        .linkList {
          display: flex;
          flex-direction: column;
          gap: 10px;

          @media (max-width: 1000px) {
            width: 100%;
            flex-direction: row;
            flex-wrap: wrap;
          }

          span {
            font-size: 13px;
            font-weight: 600;
            cursor: pointer;
          }
        }
      }
    }

    .bottom {
      margin-top: 2rem;
      .copyright {
        font-size: 11px;
        font-weight: 700;
        text-transform: uppercase;
        display: flex;
        align-items: center;
      }
    }
  }
}
