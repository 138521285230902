.sidebar {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 10000;

  .wrapper {
    float: left;
    height: 100vh;
    width: 85%;
    background-color: var(--cream-color);
    display: flex;
    flex-direction: column;

    .top {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 1rem;
      margin-left: 1rem;
      margin-top: 1.2rem;

      button {
        color: var(--primary-color);
        border-radius: 50%;
        font-size: 2.2rem;
        font-weight: 900;
        line-height: 2rem;
        padding: 8px;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .middle {
      width: 100%;

      .list {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
        margin-top: 20px;

        .link,
        .active {
          display: flex;
          align-items: center;
          gap: 10px;
          width: 100%;
          padding: 8px 25px;
          font-size: 1.2rem;
          font-weight: 600;
          color: var(--primary-color);
        }
        .active {
          color: var(--cream-color);
          background-color: var(--primary-color);
          border-radius: 5px;
        }
      }
    }

    .bottom {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 2rem;

      .linkContainer {
        font-weight: bold;
        color: var(--primary-color);
        display: flex;
        gap: 10px;

        .divider {
          font-weight: bold;
        }
      }

      .bottomItems {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        width: 100%;

        .profile {
          text-align: center;
          width: 80%;
          padding: 8px;
          border: 2px solid var(--primary-color);
          font-size: 1.2rem;
          font-weight: bold;
          border-radius: 5px;
        }

        button {
          text-align: center;
          width: 80%;
          padding: 8px;
          background-color: var(--primary-color);
          font-size: 1.2rem;
          font-weight: bold;
          border-radius: 5px;
          color: var(--cream-color);
        }
      }
    }

    .socials {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: auto;
      margin-bottom: 2rem;
      .list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;

        .listItem {
          background-color: var(--primary-color);
          color: var(--cream-color);
          padding: 8px;
          border-radius: 50%;
        }
      }
    }
  }
}
