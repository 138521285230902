@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Metal&display=swap");

#root {
  --cream-color: #f8f5e9;
  --primary-color: #32412b;
  /* --primary-color: #086839; */
  --secondary-color: #eb6825;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
}

::-webkit-scrollbar {
  width: 10px;
  border-radius: 5px;
  background-color: rgb(229, 226, 226);
}

::-webkit-scrollbar-thumb {
  background-color: rgb(184, 183, 183);
  border-radius: 5px;
}

.link {
  text-decoration: none;
  color: inherit;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
