.navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cream-color);
  overflow: hidden;

  .wrapper {
    width: 90%;
    padding: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1000px) {
      width: 100%;
    }

    .left {
      width: 25%;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 1000px) {
        width: auto;
      }
      .logo {
        width: 60%;

        @media (max-width: 1000px) {
          width: 85%;
          margin: auto;
        }
      }
    }

    .center {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      .list {
        list-style: none;
        display: flex;
        gap: 15px;
        font-weight: bold;
        color: var(--primary-color);

        .active {
          padding: 5px;
          border-bottom: 2px solid var(--primary-color);
        }
      }
      @media (max-width: 1000px) {
        display: none;
      }
    }

    .right {
      width: 25%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 30px;

      @media (max-width: 1000px) {
        width: 50%;
        gap: 10px;
        justify-content: flex-end;
      }

      .linkContainer {
        font-weight: bold;
        color: var(--primary-color);
        display: flex;
        gap: 10px;

        @media (max-width: 1000px) {
          display: none;
        }

        .divider {
          font-weight: bold;
        }
      }

      .cartIcon {
        border: 1px solid green;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;

        img {
          width: 30px;
          padding: 8px;
        }

        .items {
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          position: absolute;
          top: -10px;
          right: -10px;
          background-color: var(--primary-color);
          color: var(--cream-color);
          font-weight: bold;
        }
      }
    }

    .menuContainer {
      width: 50%;
      display: none;

      @media (max-width: 1000px) {
        display: block;
      }

      .menu {
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 8px;
        color: var(--primary-color);
        font-size: 1.9rem;
      }
    }
  }
}
